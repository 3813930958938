//action types
import ApiService from "@/core/services/api.service";

export const GET_PLAYZONE_INFO = "getPlayzoneInfo";
export const GET_PLAYERS_INFO = "getPlayersList";
export const UPDATE_GAMES_INFO = "updateGamesInfo";

//mutation types
export const SET_PLAYZONE_INFO = "setPlayzoneInfo";
export const SET_PLAYERS_INFO = "setPlayersList";
export const SET_GAMES_INFO = "setGamesInfo";

const state = {
  playzone_info: {},
  playzone_players_info: [],
  playzone_games_info: []
};

const getters = {
  playzoneInfo(state) {
    return state.playzone_info;
  },
  getPlayzoneId({ playzone_info }) {
    return playzone_info.id;
  },
  gamesInfo(state) {
    return state.playzone_games_info;
  },
  playersInfo(state) {
    return state.playzone_players_info;
  }
  /*
    getSinglePlayerInfo(state, playerId){
        if (state.playzone_players_info) {
            return state.playzone_players_info.filter((player) => {
                return player.id === playerId
            })
        }
    }*/
};

const actions = {
  [GET_PLAYZONE_INFO]({ commit }, payload) {
    return new Promise(resolve => {
      ApiService.post("playzone-get", payload)
        .then(res => {
          const games = res.data.games;
          let playzoneInfos = res.data;
          delete playzoneInfos["games"];
          const playersSet = new Set();
          games.forEach(game => {
            if (game.users.length > 0) {
              game.users.forEach(user => {
                playersSet.add(user);
              });
            }
          });
          commit(SET_PLAYZONE_INFO, playzoneInfos);
          commit(SET_GAMES_INFO, games);
          commit(SET_PLAYERS_INFO, [...playersSet]);

          resolve(games);
        })
        .catch(err => {
          console.log(err);
        });
    });
  },
  [UPDATE_GAMES_INFO](context, payload) {
    context.commit(SET_GAMES_INFO, payload);
  },
  [GET_PLAYERS_INFO](context, payload) {
    context.commit(SET_PLAYERS_INFO, payload);
  }
};
const mutations = {
  [SET_PLAYZONE_INFO](state, playzone_info) {
    state.playzone_info = playzone_info;
  },
  [SET_GAMES_INFO](state, games_info) {
    state.playzone_games_info = games_info;
  },
  [SET_PLAYERS_INFO](state, players_info) {
    state.playzone_players_info = players_info;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
