import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import CurrentUser from "@/core/services/currentUser.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  user: CurrentUser.getUser(),
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    //console.log(credentials);
    return new Promise(resolve => {
      ApiService.post("login", credentials)
        .then(res => {
          // console.log("Here what post returns", data);
          //console.log(res.data);
          context.commit(SET_AUTH, res.data);
          resolve(res.data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise(resolve => {
      ApiService.post("playzone-subscribe", credentials)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          console.log(response);
          context.commit(SET_ERROR, response.errors);
        });
    });
  },
  [VERIFY_AUTH](context) {
    //console.log(this.state);
    if (JwtService.getToken()) {
      ApiService.setHeader(JwtService.getToken());
      ApiService.get("verify")
        .then(({ data }) => {
          if (data.status === true) {
            //context.commit(SET_AUTH, state.user);
          } else {
            context.commit(PURGE_AUTH);
            console.log(data);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_PASSWORD](context, payload) {
    return ApiService.put("password", payload).then(({ data }) => {
      context.commit(SET_PASSWORD, data);
      return data;
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    JwtService.saveToken(user.token);
    CurrentUser.saveUser(user);
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
    CurrentUser.destroyUser();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
