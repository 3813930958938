//action types
import ApiService from "@/core/services/api.service";

export const GET_COMS_INFO = "getComsInfo";
export const GET_RECEIVED_EMAILS = "getReceivedEmails";

//mutation types
export const SET_COMS_INFO = "setComsInfo";
export const SET_RECEIVED_EMAILS = "setReceivedEmails";

const state = {
  coms_info: {},
  emailsReceived: {}
};

const getters = {
  getComs(state) {
    return state.coms_info;
  },
  getReceivedEmails(state) {
    return state.emailsReceived;
  }
};

const actions = {
  [GET_COMS_INFO]({ commit }, payload) {
    return new Promise(resolve => {
      ApiService.post("communication-get", payload)
        .then(res => {
          commit(SET_COMS_INFO, res.data);
          resolve(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    });
  },
  [GET_RECEIVED_EMAILS](context, payload) {
    context.commit(SET_RECEIVED_EMAILS, payload);
  }
};
const mutations = {
  [SET_COMS_INFO](state, payload) {
    state.coms_info = payload;
  },
  [SET_RECEIVED_EMAILS](state, payload) {
    state.emailsReceived = payload;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
