const CURRENT_USER = "current_user";

export const getUser = () => {
  return JSON.parse(window.localStorage.getItem(CURRENT_USER));
};

export const saveUser = user => {
  window.localStorage.setItem(CURRENT_USER, JSON.stringify(user));
};

export const destroyUser = () => {
  window.localStorage.removeItem(CURRENT_USER);
};

export default { getUser, saveUser, destroyUser };
