import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/custom-error",
      name: "error",
      component: () => import("@/view/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/view/pages/error/Error-1.vue")
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/view/pages/error/Error-2.vue")
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/view/pages/error/Error-3.vue")
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/view/pages/error/Error-4.vue")
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/view/pages/error/Error-5.vue")
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/view/pages/error/Error-6.vue")
        }
      ]
    },
    {
      name: "playzone-subscribe",
      path: "/playzone-subscribe",
      component: () => import("@/view/pages/playzone/Subscribe"),
      children: [
        {
          name: "subscribe",
          path: ":gameId/:platform/:platformId",
          component: () => import("@/view/pages/playzone/Register"),
          meta: {
            title: "Inscription"
          }
        },
        {
          name: "validate-subscription",
          path: "validate",
          component: () => import("@/view/pages/playzone/Validate"),
          meta: {
            title: "Validation Incription"
          }
        },
        {
          name: "email-validate",
          path: "email-validation/:token",
          component: () => import("@/view/pages/playzone/EmailValidation"),
          meta: {
            title: "Validation Email"
          }
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/login_pages/Login-1"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/error/Error-1.vue")
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/login_pages/Login-1")
        }
      ]
    },
    {
      path: "*/login",
      name: "plz-login",
      component: () => import("@/view/pages/auth/login_pages/Login-1"),
      meta: {
        title: "Connexion"
      }
    },
    {
      path: "*",
      component: () => import("@/view/layout/Layout"),
      /*redirect: "dashboard",*/
      children: [
        {
          path: "dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue"),
          meta: {
            title: "Tableau de bord"
          }
        },
        {
          path: "builder",
          name: "builder",
          component: () => import("@/view/pages/Builder.vue")
        },
        {
          name: "all-games",
          path: "games",
          component: () => import("@/view/pages/Games/Games.vue"),
          meta: {
            title: "Tous les jeux"
          }
        },
        {
          name: "game",
          path: "/*/games/:index",
          component: () => import("@/view/pages/Games/Game.vue")
        },
        {
          name: "com-center",
          path: "com-center",
          component: () => import("@/view/pages/Comcenter/Comcenter.vue"),
          redirect: "com-center/all",
          children: [
            {
              name: "create",
              path: "create",
              component: () => import("@/view/pages/Comcenter/Create.vue")
            },
            {
              name: "all-coms",
              path: "all",
              component: () => import("@/view/pages/Comcenter/All.vue")
            }
          ]
        }
      ]
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue")
    }
  ]
});
