import Vue from "vue";
import VueMoment from "vue-moment";
import wysiwyg from "vue-wysiwyg";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import "vue-wysiwyg/dist/vueWysiwyg.css";
///import MockService from "@/core/mock/mock.service";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";

// API service init
ApiService.init();

// Remove this to disable mock API
//MockService.init();
Vue.use(VueMoment);
Vue.use(wysiwyg, {
  hideModules: { image: true, table: true, code: true, separator: true }
}); // config is optional. more below

router.beforeEach((to, from, next) => {
  document.title = to.meta.title + " | 3W Games" || "3W Games";
  // Ensure we checked auth before each page load.
  if (to.path !== "playzone-subscribe/*") {
    Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);
  }

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});
/*Vue.config.devtools = false;
Vue.config.debug = false;
Vue.config.silent = true;*/
new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");